/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Company = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
        companyCode: {
    type: 'string',
    isRequired: true,
},
        companyPhone: {
    type: 'string',
},
        createdAt: {
    type: 'string',
},
    },
};