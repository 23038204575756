































































































import { Company } from "@/api/models/Company";
import { VForm } from "@/common/vuetify_types";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import EditConfirmModal from "@/views/super_admin/company/EditConfirmModal.vue";
import PurchaseTwilioNumber from "./PurchaseTwilioNumber.vue";
import { PurchaseNumber } from "@/api";

@Component({
  components: {
    EditConfirmModal,
    PurchaseTwilioNumber,
  },
})
export default class EditCompany extends Vue {
  @Ref("editForm") readonly form!: VForm;
  @Prop(Object) readonly company!: Company;

  valid = true;
  loading = false;
  buying = false;
  showList = false;
  editConfirmModal = false;
  name = this.company.name;
  companyCode = this.company.companyCode;
  phoneNumber = this.company.companyPhone;
  formRules = [
    (v: string) => !!v || "Required",
    (v: string) => !v || v.trim() !== "" || "Space only is not accepted",
  ];
  emailValid = (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid";

  created() {
    const localNumber = localStorage.getItem("phoneNumber");
    if (localNumber !== null) {
      this.phoneNumber = JSON.parse(localNumber).number;
    }
  }

  close() {
    this.$emit("close");
  }

  async save() {
    const isValid = this.form.validate();
    if (isValid) {
      const requestBody = {
        id: this.company.id,
        name: this.name.trim(),
        companyCode: this.companyCode.trim(),
        createdAt: this.company.createdAt,
        companyPhone: this.phoneNumber,
      } as Company;
      try {
        this.loading = true;
        const res = await this.$store.dispatch(
          "superAdmin/updateCompany",
          requestBody,
        );
        if (res) {
          this.$store.dispatch("sms/setSelectedNumber", null);
        }
        this.editConfirmModal = false;
        this.$emit("close");
        this.loading = false;
        this.form.reset();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async purchaseNumber(number: PurchaseNumber) {
    this.buying = true;
    const res = await this.$store.dispatch("sms/purchaseNumber", number);
    if (res) {
      this.$store.dispatch("sms/setSelectedNumber", number);
      this.phoneNumber = number.number;
      this.showList = !this.showList;
    }
    this.buying = false;
  }
}
