/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProvisionCompanyRequestBody = {
    properties: {
        name: {
    type: 'string',
    isRequired: true,
},
        companyCode: {
    type: 'string',
    isRequired: true,
},
        administratorEmail: {
    type: 'string',
    isRequired: true,
},
        administratorFirstName: {
    type: 'string',
    isRequired: true,
},
        administratorLastName: {
    type: 'string',
    isRequired: true,
},
        administratorPhone: {
    type: 'string',
},
    },
};