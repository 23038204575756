import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  ListAvailableNumber,
  OpenAPI,
  PurchaseNumber,
  SmsService,
} from "@/api";
import resolve from "@/common/resolver";

type SmsState = {
  availableNumbers: ListAvailableNumber;
  selectedNumber: PurchaseNumber | null;
};

const state: SmsState = {
  availableNumbers: [],
  selectedNumber: null,
};

const getters: GetterTree<SmsState, RootState> = {
  availableNumbers: state => state.availableNumbers,
  selectedNumber: state => state.selectedNumber,
};

const mutations: MutationTree<SmsState> = {
  SET_AVAILABLE_NUMBERS: (state, availableNumbers) => {
    state.availableNumbers = availableNumbers;
  },
  SET_SELECTED_NUMBER: (state, selectedNumber) => {
    state.selectedNumber = selectedNumber;
  },
};

const actions: ActionTree<SmsState, RootState> = {
  fetchAvailableNumbers: async (
    { commit },
    { areaCode, contains }: { areaCode: string; contains: string },
  ) => {
    try {
      const availableNumbers = await SmsService.listAvailableNumbers({
        areaCode,
        contains,
      });
      commit("SET_AVAILABLE_NUMBERS", availableNumbers);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  purchaseNumber: async (
    { commit, rootState },
    requestBody: PurchaseNumber,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      await SmsService.purchaseNumber({
        requestBody,
      });
      commit("alert/SET_SHOW_SUCCESS", "Number purchased successfully", {
        root: true,
      });

      return true;
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
      return false;
    }
  },
  smsWithMedia: async (
    { commit },
    {
      message,
      to,
      from,
      companyId,
      file,
    }: {
      message: string;
      to: number[];
      from: number;
      companyId: number;
      file?: File;
    },
  ) => {
    const formData = new FormData();
    if (file) formData.append("file", file, file?.name);
    formData.append("from", JSON.stringify(from));
    formData.append("to", JSON.stringify(to));
    formData.append("message", message);
    try {
      const res = await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/send-sms`,
        {
          method: "POST",
          body: formData,
          headers: {
            authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
          },
        },
      );
      return await res.json();
    } catch (e) {
      if (e instanceof Error) {
        commit("alert/SET_SHOW_ERROR", e.message, { root: true });
      }
    }
  },
  setSelectedNumber: ({ commit }, number: PurchaseNumber | null) => {
    commit("SET_SELECTED_NUMBER", number);
    if (number == null) {
      localStorage.removeItem("phoneNumber");
    } else {
      localStorage.setItem("phoneNumber", JSON.stringify(number));
    }
  },
};

export const sms: Module<SmsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
