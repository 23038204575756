






























































































































import { VForm } from "@/common/vuetify_types";
import { Component, Ref, Vue } from "vue-property-decorator";
import PurchaseTwilioNumber from "./PurchaseTwilioNumber.vue";
import { PurchaseNumber } from "@/api";

@Component({
  components: {
    PurchaseTwilioNumber,
  },
})
export default class AddCompany extends Vue {
  @Ref("companyForm") readonly form!: VForm;

  valid = true;
  loading = false;
  showList = false;
  buying = false;
  name = "";
  phoneNumber = "";
  companyCode = "";
  administratorEmail = "";
  administratorFirstName = "";
  administratorLastName = "";
  formRules = [
    (v: string) => !!v || "Required",
    (v: string) => !v || v.trim() !== "" || "Space only is not accepted",
  ];
  emailValid = (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid";

  created() {
    const localNumber = localStorage.getItem("phoneNumber");
    if (localNumber !== null) {
      this.phoneNumber = JSON.parse(localNumber).number;
    }
  }

  async createCompany() {
    const isValid = this.form.validate();
    if (isValid) {
      const requestBody = {
        name: this.name.trim(),
        companyCode: this.companyCode.trim(),
        administratorEmail: this.administratorEmail.trim(),
        administratorFirstName: this.administratorFirstName.trim(),
        administratorLastName: this.administratorLastName.trim(),
        administratorPhone: this.phoneNumber.trim(),
      };
      try {
        this.loading = true;
        const res = await this.$store.dispatch(
          "superAdmin/createCompany",
          requestBody,
        );
        this.loading = false;
        if (res) {
          this.form.reset();
          this.$store.dispatch("sms/setSelectedNumber", null);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  backToList() {
    this.$router.push({ path: "/company-list" });
  }

  async purchaseNumber(number: PurchaseNumber) {
    this.buying = true;
    const res = await this.$store.dispatch("sms/purchaseNumber", number);
    if (res) {
      this.$store.dispatch("sms/setSelectedNumber", number);
      this.phoneNumber = number.number;
      this.showList = !this.showList;
    }
    this.buying = false;
  }
}
