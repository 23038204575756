/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PurchaseNumber = {
    properties: {
        number: {
    type: 'string',
    isRequired: true,
},
        capabilities: {
    properties: {
        mms: {
    type: 'boolean',
    isRequired: true,
},
        sms: {
    type: 'boolean',
    isRequired: true,
},
        voice: {
    type: 'boolean',
    isRequired: true,
},
        fax: {
    type: 'boolean',
    isRequired: true,
},
    },
    isRequired: true,
},
        price: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        country: {
    type: 'string',
    isRequired: true,
},
        locality: {
    type: 'string',
    isRequired: true,
},
        postalCode: {
    type: 'string',
    isRequired: true,
},
    },
};