/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListAvailableNumber } from '../models/ListAvailableNumber';
import type { PurchaseNumber } from '../models/PurchaseNumber';
import { request as __request } from '../core/request';

export class SmsService {

    /**
     * List available number for purchase
     * @returns ListAvailableNumber list of available numbers for purchase
     * @throws ApiError
     */
    public static async listAvailableNumbers({
contains,
areaCode,
}: {
contains?: string,
areaCode?: string,
}): Promise<ListAvailableNumber> {
        const result = await __request({
            method: 'GET',
            path: `/super-admin/phone/listAvailableNumbers`,
            query: {
                'contains': contains,
                'areaCode': areaCode,
            },
        });
        return result.body;
    }

    /**
     * Purchase number for sms
     * @returns any number added to company successfully
     * @throws ApiError
     */
    public static async purchaseNumber({
requestBody,
}: {
requestBody: PurchaseNumber,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/super-admin/phone/purchase-number`,
            body: requestBody,
        });
        return result.body;
    }

}