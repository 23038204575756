

































































































import { ListAvailableNumber, PurchaseNumber } from "@/api";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PurchaseTwilioNumber extends Vue {
  loading = false;
  contain = "";
  areaCode = "";

  headers = [
    {
      text: "Number",
      align: "start",
      value: "number",
    },
    { text: "Voice", value: "voice" },
    { text: "SMS", value: "sms" },
    { text: "MMS", value: "mms" },
    { text: "Monthly Fee", value: "price" },
    { text: "Action", value: "action" },
  ];

  created() {
    this.fetchAvailableNumbers();
  }

  async fetchAvailableNumbers() {
    this.loading = true;
    await this.$store.dispatch("sms/fetchAvailableNumbers", {
      areaCode: this.areaCode,
      contains: this.contain,
    });
    this.loading = false;
  }

  get availableNumbers() {
    const list = this.$store.getters[
      "sms/availableNumbers"
    ] as ListAvailableNumber;

    return list;
  }

  purchaseNaumber(number: PurchaseNumber) {
    this.$emit("purchase", number);
  }

  close() {
    this.$emit("close");
  }
}
