import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { Announcement, AnnouncementList, AnnouncementService } from "@/api";
import _ from "lodash";
import moment from "moment";

type AnnouncementState = {
  announcements: AnnouncementList;
  announceToday: boolean;
  announcementTab: boolean;
};

const state: AnnouncementState = {
  announcements: [],
  announceToday: false,
  announcementTab: false,
};

const getters: GetterTree<AnnouncementState, RootState> = {
  announcements: state => state.announcements,
  announceToday: state => state.announceToday,
  announcementTab: state => state.announcementTab,
};

const mutations: MutationTree<AnnouncementState> = {
  SET_ANNOUNCE_TODAY: (state, announceToday) => {
    state.announceToday = announceToday;
  },
  SET_ANNOUNCE_TAB: (state, announcementTab) => {
    state.announcementTab = announcementTab;
  },
  SET_ANNOUNCEMENTS: (state, announcments) => {
    state.announcements = announcments;
  },
  ADD_ANNOUNCEMENTS: (state, announcement) => {
    if (!state.announcements) {
      state.announcements = [];
    }
    state.announcements.push(announcement);
    state.announcements = _.uniqBy(
      _.orderBy(state.announcements, ["id"], ["desc"]),
      "id",
    );
  },
  UPDATE_ANNOUNCEMENTS: (state, announcment) => {
    let index = state.announcements?.findIndex(
      item => item.id == announcment.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.announcements?.splice(index, 1);
      state.announcements?.push(announcment);
      state.announcements = _.uniqBy(
        _.orderBy(state.announcements, ["id"], ["desc"]),
        "id",
      );
    }
  },
  DELETE_ANNOUNCEMENTS: (state, announcement) => {
    let index = state.announcements?.findIndex(
      item => item.id == announcement.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.announcements?.splice(index, 1);
    }
  },
};

const actions: ActionTree<AnnouncementState, RootState> = {
  fetchAnnouncements: async ({ commit, rootState }) => {
    commit("SET_ANNOUNCE_TODAY", false);
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const announcements = await AnnouncementService.getAnnouncementList({
        companyId,
      });
      if (announcements) {
        for (let i = 0; i < announcements.length; i++) {
          if (moment(announcements[i].createdAt).isSame(moment(), "day")) {
            commit("SET_ANNOUNCE_TODAY", true);
            break;
          }
        }
      }
      commit("SET_ANNOUNCEMENTS", announcements);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addAnnouncement: async ({ commit, rootState }, requestBody: Announcement) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const announcement = await AnnouncementService.addAnnouncement({
        companyId,
        requestBody,
      });
      commit("ADD_ANNOUNCEMENTS", announcement);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateAnnouncement: async (
    { commit, rootState },
    requestBody: Announcement,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const announcement = await AnnouncementService.updateAnnouncement({
        companyId,
        requestBody,
      });
      commit("UPDATE_ANNOUNCEMENTS", announcement);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  deleteAnnouncement: async (
    { commit, rootState },
    requestBody: Announcement,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const announcement = await AnnouncementService.deleteAnnouncement({
        companyId,
        requestBody,
      });
      commit("DELETE_ANNOUNCEMENTS", announcement);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  toggleAnnouncementTab: ({ commit, state }) => {
    commit("SET_ANNOUNCE_TAB", !state.announcementTab);
  },
};

export const announcement: Module<AnnouncementState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
