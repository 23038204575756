const numberOnly = (v: number) =>
  /^[0-9]+$/.test(v?.toString().trim()) || "The value must be number";

const intCheck = (v: number) =>
  Number.isInteger(Number(v)) || "The value must be number";

const noSpace = (v: string) =>
  !v || v.trim() !== "" || "Space only is not accepted";

const requiredSelect = (v: string) =>
  (v !== "" && v !== undefined) || "Required";

const requiredSelectWithZero = (v: string) =>
  (v !== "" && v !== undefined && Number(v) !== 0) || "Required";

const requriedNumber = (v: number) => !!v || v === 0 || "Required";

const requiredString = (v: string) => !!v || "Required";

const requireAutoSelectNumber = (v: number[]) => {
  if (!v || v.length == 0) {
    return "You must select at least one.";
  }
  return true;
};

const requiredComboBox = (v: string) => v?.length > 0 || "Required";

const maxFileSize = (value: File, size: number) =>
  !value ||
  value.size < size * 1000000 ||
  `File size should be less than ${size} MB!`;

const emailValid = (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid";

const maxLen = (v: string, len: number) =>
  v?.length <= len || `Must be less than ${len} characters`;

const minLen = (v: string, len: number) =>
  (v && v.length >= len) || `Must contain ${len} character`;

const maxLenInt = (v: string, len: number) =>
  v?.length === len || `Must contain ${len} digits`;

const emailOrIdValid = (v: string) =>
  /.+@.+\..+/.test(v.trim()) ||
  /^[0-9]+$/.test(v.trim()) ||
  "Enter valid email or employee ID";

const stringOnly = (v: string) =>
  /^[a-zA-Z]+$/.test(v.toString()) || "Must only contain a word";

const floatOnly = (v: number) => !isNaN(v) || "Invalid number";

const rejectZero = (v: number) => v != 0 || "Invalid input";

const validatePhone = (v: string) =>
  /^\+[1-9]\d{1,14}$/.test(v) || "Invalid phone number";

export {
  numberOnly,
  noSpace,
  requiredSelect,
  requiredString,
  maxFileSize,
  emailValid,
  maxLen,
  minLen,
  maxLenInt,
  requriedNumber,
  requireAutoSelectNumber,
  emailOrIdValid,
  requiredComboBox,
  stringOnly,
  intCheck,
  floatOnly,
  rejectZero,
  requiredSelectWithZero,
  validatePhone,
};
