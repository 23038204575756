











import { Component, Vue } from "vue-property-decorator";
import NavigationRail from "@/components/NavigationRail.vue";
import AppBarMain from "@/components/AppBarMain.vue";
import AnnouncementLayout from "@/views/announcement/AnnouncementLayout.vue";

@Component({
  components: { AppBarMain, NavigationRail, AnnouncementLayout },
})
export default class Layout extends Vue {
  created() {
    this.$store.dispatch("company/loadDepartments");
  }
}
