


















































import { Component, Vue } from "vue-property-decorator";
import AnnouncementCard from "@/components/announcement/AnnouncementCard.vue";
import AnnouncementForm from "@/components/announcement/AnnouncementForm.vue";
import * as roleUtils from "@/common/role_utils";

@Component({
  components: {
    AnnouncementCard,
    AnnouncementForm,
  },
})
export default class AnnouncementLayout extends Vue {
  announcementDialog = false;
  counter = 0;

  created() {
    this.$store.dispatch("announcement/fetchAnnouncements");
  }

  get isOpen() {
    return this.$store.getters["announcement/announcementTab"];
  }

  set isOpen(value: boolean) {
    if (!value) this.$store.dispatch("announcement/toggleAnnouncementTab");
  }

  get announcements() {
    return this.$store.getters["announcement/announcements"];
  }

  get isAdmin() {
    return roleUtils.isAdmin();
  }
}
