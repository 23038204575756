

































































































































































































































import { Department, DepartmentEmployeeAssignment, Employee } from "@/api";
import { selfData } from "@/common/role_utils";
import { VForm } from "@/common/vuetify_types";
import { maxFileSize, requiredString } from "@/tool/validation";
import _ from "lodash";
import { Component, Vue, Ref } from "vue-property-decorator";
import shifts from "@/common/shift_type";

@Component
export default class MessageLayout extends Vue {
  @Ref("form") form!: VForm;
  shifts = [{ id: 3, name: "All Shift" }, ...shifts];
  shift = 3;
  message: string | undefined = "";
  search = "";
  showAll = false;
  sending = false;
  employeeId: number[] = [];
  departmentId = 0;
  counter = 0;
  to: number[] = [];
  from = "+15203143777";
  companyId: number = selfData().companyId;
  res: { errors: string[]; total: number } | null = null;
  url: string | ArrayBuffer | null | undefined = "";
  document: File | null = null;
  documentName = "";
  fileErr = "";
  textRules = [requiredString];
  fileRules = [(v: File) => maxFileSize(v, 5)];
  selectAllValue = false;

  async created() {
    await this.$store.dispatch("directory/loadAllEmployees", true);
  }

  get messages(): string[] {
    return this.$store.getters["message/messages"];
  }

  get employeeIds() {
    return this.showAll ? this.employeeId : this.employeeId.slice(0, 3);
  }

  get employees(): { alphabet: string; records: Employee[] }[] {
    const search = !this.search ? "" : this.search;
    const employees = this.$store.state.directory.employees;
    const list = _.reduce(
      _.orderBy(
        _.filter(
          _.filter(
            _.filter(employees ?? [], (e: Employee) => {
              if (this.shift < 3 && this.shift >= 0) {
                return this.shift == e.shift;
              } else {
                return true;
              }
            }),
            (emp: Employee) => {
              if (this.departmentId != 0) {
                return emp.primaryDepartmentId == this.departmentId;
              } else {
                return true;
              }
            },
          ),
          (employee: Employee) =>
            _.includes(
              `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
              search.toLowerCase(),
            ),
        ),
        [
          (employee: Employee) =>
            `${employee.firstName?.toLowerCase()} ${employee.lastName?.toLowerCase()}`,
        ],
        ["asc"],
      ),
      (acc: { alphabet: string; records: Employee[] }[], cur) => {
        const alphabet = cur.firstName[0].toUpperCase();
        if (!acc[alphabet]) acc[alphabet] = { alphabet, records: [cur] };
        else acc[alphabet].records.push(cur);
        return acc;
      },
      [],
    );
    return Object.values(list);
  }

  getEmployeeName(id: number): string {
    const employees = this.$store.state.directory.employees;
    const employee = _.find(
      employees,
      (employee: Employee) => employee.id == id,
    );
    return `${employee.firstName} ${employee.lastName}`;
  }

  primaryDepartment(
    departments: DepartmentEmployeeAssignment[],
    primaryDepartment: number,
  ) {
    const primary = departments.find(
      item => item.departmentId === primaryDepartment,
    );
    return primary?.departmentName;
  }

  selectEmployee(employeeId: number) {
    if (this.employeeId.includes(employeeId)) {
      const index = this.employeeId.indexOf(employeeId);
      this.employeeId.splice(index, 1);
    } else {
      this.employeeId.push(employeeId);
    }
  }

  clearPayload() {
    this.message = undefined;
    this.to = [];
    this.employeeId = [];
    this.document = null;
    this.form.reset();
    this.form.resetValidation();
  }

  async submit() {
    this.res = null;
    this.to = this.employeeId;
    this.sending = true;
    const payload = {
      message: this.message,
      to: this.to,
      from: this.from,
      file: this.document || undefined,
      companyId: selfData().companyId,
    };

    const res = await this.$store.dispatch("sms/smsWithMedia", payload);
    this.res = res;
    this.clearPayload();
    this.sending = false;
  }

  get departments(): Department[] {
    const departments = this.$store.state.company.departments;
    if (departments) {
      return [
        { id: 0, name: "All Department", companyId: 0, isArchived: false },
        ...departments,
      ];
    } else {
      return [];
    }
  }

  onChangeFile(file: File) {
    this.fileErr = "";
    this.counter++;
    if (file) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event) {
          this.documentName = file.name;
          this.url = URL.createObjectURL(file);
        }
      };
      reader.readAsArrayBuffer(file);
      this.document = file;
    }
  }

  selectAll() {
    this.employeeId = [];
    if (this.selectAllValue) {
      _.forEach(this.employees, emp => {
        _.forEach(emp.records, e => this.employeeId.push(e.id));
      });
    }
  }
}
