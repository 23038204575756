




























































import { Announcement, AnnouncementResponse } from "@/api";
import moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";
import { dateFormatWithTime } from "@/common/date_format";
import AnnouncementForm from "@/components/announcement/AnnouncementForm.vue";
import ConfirmationModule from "@/components/confirmationModule/ConfirmationModule.vue";
import { selfData } from "@/common/role_utils";

@Component({
  components: {
    AnnouncementForm,
    ConfirmationModule,
  },
})
export default class AnnouncementCard extends Vue {
  @Prop() readonly announcement!: AnnouncementResponse;

  formatDate = dateFormatWithTime;
  selfData = selfData();
  counter = 0;
  announcementDialog = false;
  confirmDialog = false;
  deleting = false;
  updateAnnouncement: Announcement = {
    id: this.announcement.id,
    companyId: this.announcement.companyId,
    employeeId: this.announcement.postedBy.id,
    title: this.announcement.title,
    description: this.announcement.description,
    createdAt: this.announcement.createdAt,
  };

  getColor(date: string): string {
    if (moment(date).isSame(moment(), "day")) return "primary";
    return "#8b8b8b";
  }

  async deleteAnnouncement() {
    this.deleting = true;
    await this.$store.dispatch(
      "announcement/deleteAnnouncement",
      this.updateAnnouncement,
    );
    this.deleting = false;
    this.confirmDialog = !this.confirmDialog;
  }
}
